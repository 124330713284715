import Vue from 'vue';

const firebaseConfig = {
    apiKey: 'AIzaSyA2sVcNT3VmsSvTVmtvGTucgkqVfzeN7GI',
    authDomain: 'winnies-kitchen.firebaseapp.com',
    databaseURL: 'https://winnies-kitchen-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'winnies-kitchen',
    storageBucket: 'winnies-kitchen.appspot.com',
    messagingSenderId: '567069082542',
    appId: '1:567069082542:web:a0d19530a763dde040be3f',
};

Vue.mixin({
    data: function () {
        return {
            get companyName() {
                return '珍姐廚房'; //公司名稱
            },
            get regFormDomain() {
                return 'https://web.winnieskitchen.xyz/';
            },
        };
    },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = '#e8f2fa';

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = '#000';

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
